<template>
  <v-container fluid class="credit-note-detail bg-white">
    <v-row v-if="page_loaded" class="mx-0">
      <v-col md="12" class="pt-0">
        <v-layout>
          <v-flex md6 class="d-flex">
            <span class="color-custom-blue font-weight-700 font-size-20">{{
              detail.title
            }}</span>
            <CustomStatus
              :status="detail.status"
              endpoint="credit-note/status"
            ></CustomStatus>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  v-if="detail.is_sent"
                  color="orange lighten-1 white--text"
                  label
                  class="text-uppercase font-weight-600"
                >
                  Sent to Customer
                </v-chip>
              </template>
              <span>Sent Date: {{ formatDate(detail.send_date) }}</span>
            </v-tooltip>
          </v-flex>
          <v-flex md6 class="text-right">
            <v-btn
              v-if="detail.status == 1"
              v-on:click="push_to_edit()"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
            >
              Edit
            </v-btn>
            <v-menu
              transition="slide-y-transition"
              bottom
              content-class="custom-menu-list"
              offset-y
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  v-bind="attrs"
                  v-on="on"
                >
                  More... <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-for="(more, index) in more_actions">
                  <v-list-item
                    link
                    v-on:click="update_more_action(more.action)"
                    :key="index"
                    :disabled="more.disabled"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                      <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="font-weight-500 font-size-14">{{
                      more.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="goBack()"
            >
              <v-icon small left>mdi-keyboard-backspace</v-icon>
              Back
            </v-btn>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col md="5">
        <span class="color-custom-blue font-weight-700 font-size-19">
          <router-link
            :to="
              getDefaultRoute('customer.detail', {
                params: {
                  id: detail.customer.id,
                },
              })
            "
            >{{ detail.customer.display_name }}
          </router-link>
        </span>
        <v-layout class="my-4">
          <v-flex md6 class="mr-2 custom-border-right">
            <table width="100%">
              <tr>
                <td class="font-weight-600">Showroom Location</td>
              </tr>
              <tr>
                <td class="py-0 font-size-18 font-weight-500">
                  <a
                    target="_blank"
                    :href="`https://maps.google.com/?q=${detail.property.property_address}`"
                    >{{ detail.property.property_address }}</a
                  >
                </td>
              </tr>
              <tr>
                <td class="font-weight-600">Contact details</td>
              </tr>
              <tr v-if="detail.property_person.display_name">
                <td class="py-0 font-size-18 font-weight-500">
                  {{ detail.property_person.display_name }}
                </td>
              </tr>
              <tr v-if="detail.property_person.primary_phone">
                <td class="py-0 font-size-18 font-weight-500">
                  {{ detail.property_person.primary_phone }}
                </td>
              </tr>
              <tr v-if="detail.property_person.primary_email">
                <td class="py-0 font-size-18 font-weight-500">
                  {{ detail.property_person.primary_email }}
                  <v-icon
                    v-if="detail.property_person_notify"
                    v-tippy="{
                      arrow: true,
                      arrowType: 'round',
                      animation: 'fade',
                    }"
                    content="Notification was sent to given email"
                    color="green lighten-1"
                    >mdi-check</v-icon
                  >
                </td>
              </tr>
            </table>
          </v-flex>
          <v-flex md6 class="ml-2">
            <table width="100%">
              <tr>
                <td class="font-weight-600">Billing Address</td>
              </tr>
              <tr>
                <td class="py-0 font-size-18 font-weight-500">
                  <a
                    target="_blank"
                    :href="`https://maps.google.com/?q=${detail.billing.property_address}`"
                    >{{ detail.billing.property_address }}</a
                  >
                </td>
              </tr>
              <tr>
                <td class="font-weight-600">Contact details</td>
              </tr>
              <tr v-if="detail.billing_person.display_name">
                <td class="py-0 font-size-18 font-weight-500">
                  {{ detail.billing_person.display_name }}
                </td>
              </tr>
              <tr v-if="detail.billing_person.primary_phone">
                <td class="py-0 font-size-18 font-weight-500">
                  {{ detail.billing_person.primary_phone }}
                </td>
              </tr>
              <tr v-if="detail.billing_person.primary_email">
                <td class="py-0 font-size-18 font-weight-500">
                  {{ detail.billing_person.primary_email }}
                  <v-icon
                    v-if="detail.billing_person_notify"
                    v-tippy="{
                      arrow: true,
                      arrowType: 'round',
                      animation: 'fade',
                    }"
                    content="Notification was sent to given email"
                    color="green lighten-1"
                    >mdi-check</v-icon
                  >
                </td>
              </tr>
            </table>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col md="7" class="custom-border-left gray-background">
        <table width="100%">
          <tr>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500"
              colspan="4"
            >
              <span class="color-custom-blue font-weight-700 font-size-19">{{
                detail.title
              }}</span>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              Credit Note #
            </td>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500 custom-border-right"
            >
              <label class="m-0">{{ detail.barcode }}</label>
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              &nbsp;&nbsp;Invoice #
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0">{{ invoice.barcode }}</label>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              Reference #
            </td>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500 custom-border-right"
            >
              <label class="m-0">{{ detail.reference }}</label>
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              &nbsp;&nbsp;Invoice Reference #
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0">{{ invoice.reference }}</label>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              Credit Note Date
            </td>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500 custom-border-right"
            >
              <label class="m-0">{{
                formatDate(detail.credit_note_date)
              }}</label>
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              &nbsp;&nbsp;Invoice date
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0">{{ formatDate(invoice.invoice_date) }}</label>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              Credit Amount
            </td>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500 custom-border-right"
            >
              <label class="m-0 red--text">{{
                formatMoney(detail.total)
              }}</label>
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              &nbsp;&nbsp;Invoice Payable Amount
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0 red--text">{{
                formatMoney(invoice.total)
              }}</label>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              Refund Amount
            </td>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500 custom-border-right"
            >
              <label class="m-0 green--text">{{
                formatMoney(detail.paid_amount)
              }}</label>
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              &nbsp;&nbsp;Invoice Paid Amount
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0 green--text">{{
                formatMoney(invoice.paid_amount)
              }}</label>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              Balance Amount
            </td>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500 custom-border-right"
            >
              <label class="m-0 orange--text">{{
                formatMoney(detail.pending_amount)
              }}</label>
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              &nbsp;&nbsp;Invoice Balance Amount
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0 orange--text">{{
                formatMoney(invoice.pending_amount)
              }}</label>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              Mail Sent Date
            </td>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500 custom-border-right"
            >
              <label class="m-0">{{
                formatDate(detail.credit_note_date)
              }}</label>
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-600"></td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500"></td>
          </tr>
        </table>
      </v-col>
      <v-col md="12">
        <v-tabs
          active-class="custom-tab-active"
          v-model="credit_tab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
              <!--end::Svg Icon-->
            </span>
            Overview
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#line-item">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/line-item.svg')" />
              <!--end::Svg Icon-->
            </span>
            Line Items
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#refund">
            <span
              class="svg-icon svg-icon-lg mr-4 svg-v-list-icon"
              style="transform: rotate(180deg)"
            >
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/payment.svg')" />
              <!--end::Svg Icon-->
            </span>
            Refunds
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#history">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
              <!--end::Svg Icon-->
            </span>
            History
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="credit_tab">
          <v-tab-item value="other">
            <v-container fluid>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >TERMS &amp; CONDITIONS</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <read-more
                    class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                    more-str="read more"
                    :text="
                      detail.term_conditions ||
                      '<em>No Terms &amp; Conditions</em>'
                    "
                    link="#"
                    less-str="read less"
                    :max-chars="200"
                  >
                  </read-more>
                </v-card-text>
              </v-card>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >INTERNAL NOTES &amp; ATTACHMENTS</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <v-layout class="mb-4">
                    <v-flex md6 class="custom-border-right">
                      <read-more
                        class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          detail.client_remark || '<em>No Client Notes</em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-flex>
                    <v-flex md6>
                      <read-more
                        class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="detail.admin_remark || '<em>No Admin Notes</em>'"
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    v-if="detail.documents.length"
                    class="custom-border-top"
                  >
                    <v-flex md12>
                      <FileTemplate
                        :attachments="detail.documents"
                      ></FileTemplate>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-container>
          </v-tab-item>
          <v-tab-item value="line-item">
            <CreditLineItem
              show-detail
              :db-line-items="db_line_items"
              :db-equipments="db_equipments"
              :discount-value="detail.discount_value"
              :discount-value-type="detail.discount_value_type"
              :apply-tax="detail.tax_applied"
              :adjustment-value="detail.adjustment"
            ></CreditLineItem>
          </v-tab-item>
          <v-tab-item value="refund">
            <InternalRefund
              :credit-id="credit_id"
              :c-n-barcode="detail.barcode"
              :payment-modes="payment_modes"
              :credited-amount="detail.total"
              :refunded-amount="detail.paid_amount"
              v-on:success="get_credit_note()"
            ></InternalRefund>
          </v-tab-item>
          <v-tab-item value="history">
            <InternalHistoryDetail
              type="credit-note"
              :type_id="detail.id"
            ></InternalHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <Dialog :common-dialog="status_dialog" :dialog-width="768">
      <template v-slot:title>
        <span v-if="status_type == 'marked_as_cancelled'">Cancel</span
        ><span v-if="status_type == 'marked_as_closed'">Close</span
        >&nbsp;Confirmation
      </template>
      <template v-slot:body>
        <v-container>
          <p class="font-weight-500 font-size-18 m-0">
            Are you sure, you want to
            <span v-if="status_type == 'marked_as_cancelled'">Cancel</span
            ><span v-if="status_type == 'marked_as_closed'">Close</span> this
            credit note?
          </p>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="status_loading"
          v-on:click="status_dialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No
        </v-btn>
        <v-btn
          :disabled="status_loading"
          :loading="status_loading"
          v-on:click="update_status()"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes
        </v-btn>
      </template>
    </Dialog>

    <SendMail
      :mail-dialog="mail_dialog"
      title="Send as Email"
      type="credit-note"
      v-on:close="mail_dialog = false"
      :type-id="credit_id"
      v-on:success="get_credit_note()"
    ></SendMail>
  </v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import CreditLineItem from "@/view/pages/partials/Line-Item-New";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import InternalRefund from "@/view/pages/partials/Detail/Internal-Refund.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import { GET, QUERY, PATCH } from "@/core/services/store/request.module";
import SendMail from "@/view/pages/partials/Send-Mail";
import Dialog from "@/view/pages/partials/Dialog";
import ObjectPath from "object-path";
import { toSafeInteger } from "lodash";
import JwtService from "@/core/services/jwt.service";
import { CreditNoteEventBus } from "@/core/lib/credit-note/credit-note.lib";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      more_actions: [],
      db_line_items: [],
      db_equipments: [],
      payment_modes: [],
      credit_id: 0,
      page_loaded: false,
      credit_tab: "other",
      mail_dialog: false,
      status_dialog: false,
      status_loading: false,
      status_type: null,
      invoice: {},
      detail: {
        job_title: null,
        status: 1,
        customer: {},
        property: {},
        property_person: {},
        property_person_notify: 0,
        billing: {},
        billing_person: {},
        documents: [],
        billing_person_notify: 0,
      },
    };
  },
  components: {
    Dialog,
    SendMail,
    CustomStatus,
    CreditLineItem,
    FileTemplate,
    InternalRefund,
    InternalHistoryDetail,
  },
  methods: {
    push_to_edit() {
      this.$router.push(
        this.getDefaultRoute("credit-note.update", {
          params: {
            id: ObjectPath.get(this.detail, "id"),
          },
          query: {
            customer: ObjectPath.get(this.detail, "customer.id"),
            invoice: ObjectPath.get(this.detail, "invoice.id"),
          },
        })
      );
    },
    update_more_action(action) {
      if (action == "record_refund") {
        this.record_refund();
      } else if (action == "mark_as_cancelled") {
        this.status_dialog = true;
        this.status_type = "marked_as_cancelled";
      } else if (action == "mark_as_closed") {
        this.status_dialog = true;
        this.status_type = "marked_as_closed";
      } else if (action == "send_as_email") {
        this.mail_dialog = true;
      } else if (action == "download_pdf") {
        this.download_pdf("download");
      } else if (action == "print") {
        this.download_pdf("print");
      }
    },
    record_refund() {
      this.credit_tab = "refund";
      this.$nextTick(() => {
        setTimeout(function () {
          CreditNoteEventBus.$emit("create:refund", true);
        }, 500);
      });
    },
    update_status() {
      this.status_loading = true;
      this.$store
        .dispatch(PATCH, {
          url: `credit-note/${this.credit_id}/${this.status_type}`,
        })
        .then(() => {
          this.status_dialog = false;
          this.status_type = null;
          this.get_credit_note();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.status_loading = false;
        });
    },
    download_pdf(action) {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}credit-note/${this.credit_id}/${action}?token=${token}`;
      window.open(url, "_blank");
    },
    get_line_items(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item/new",
          data,
        })
        .then(({ data }) => {
          if (data.length) {
            const result = [];

            for (let i = 0; i < data.length; i++) {
              result.push({
                id: data[i].id,
                group: data[i].group,
                group_primary: data[i].group_primary,
                product: data[i].product,
                product_id: data[i].product_id,
                serial_no: data[i].serial_no,
                eq_model: data[i].eq_model,
                location: data[i].location,
                product_type: data[i].product_type,
                has_warranty: data[i].has_warranty,
                warranty: data[i].warranty,
                description: data[i].description,
                rate: data[i].rate,
                quantity: data[i].quantity,
                uom: data[i].uom,
                total: data[i].total,
                visit_barcode: data[i].visit_barcode,
              });
            }

            _this.$nextTick(() => {
              _this.db_line_items = _this.lodash.filter(result, function (row) {
                return (
                  row.product_type == "goods" || row.product_type == "service"
                );
              });
              _this.db_equipments = _this.lodash.filter(result, {
                product_type: "equipment",
              });
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    get_credit_note() {
      this.$store
        .dispatch(GET, { url: "credit-note/" + this.credit_id })
        .then((response) => {
          this.detail = ObjectPath.get(response, "data");
          this.invoice = ObjectPath.get(response, "data.invoice");

          this.more_actions = ObjectPath.get(response, "data.more_actions");
          this.payment_modes = ObjectPath.get(response, "data.payment_modes");

          this.get_line_items({ "credit-note": this.credit_id });

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Credit Note", route: "credit-note" },
            { title: "Detail" },
            { title: ObjectPath.get(response, "data.barcode") },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.page_loaded = true;
        });
    },
  },
  mounted() {
    this.get_credit_note();
  },
  beforeMount() {
    this.credit_id = toSafeInteger(ObjectPath.get(this.$route, "params.id"));
  },
};
</script>
